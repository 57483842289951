import { FaRegCircleCheck } from "react-icons/fa6";
import Button from '../../components/buttons';
import { useNavigate } from 'react-router-dom'
import Image from "../Image";


const HeroSection = () => {

    const navigate = useNavigate()

    return <div className="relative w-full  min-h-[650px] flex flex-col items-center justify-center heroPatternImage bg-contain" >
        <div className="absolute z-[1]  w-full h-full bg-fade"></div>
        <div className="relative max-w-[1320px] ml-auto mr-auto flex flex-col items-center justify-center gap-5 z-[2] py-3">
            <span className="bg-[#fff9]  rounded-full">
                <Image src="/decentrLogo.png" style={`w-auto cursor-pointer w-24 h-24 ml-auto mr-auto`} />
                <span className="font-bold text-sm bg-haiku-gradient border-[#2bb1ff9c] bg-clip-text	text-transparent py-3 px-4 border-2 rounded-full cursor-default">Welcome to Decentr.Ai</span></span>
            <h1 className="font-bold text-5xl sm:text-6xl md:text-7xl lg:text-8xl bg-haiku-gradient bg-clip-text	text-transparent">100+ AI models</h1>
            <h3 className="font-bold text-5xl sm:text-6xl md:text-7xl lg:text-8xl -mt-4">1 subscription</h3>
            <div className="flex items-center justify-center flex-wrap gap-6 my-2">
                <div className="flex items-center justify-center gap-2">
                    <CircleCheck />
                    {/* <FaRegCircleCheck className="text-transparent w-6 h-6 bg-haiku-light-gradient" />  */}
                    <span className="text-[#8B8D98] font-medium text-base">simple fiat or crypto pricing</span>
                </div>
                <div className="flex items-center justify-center gap-2">
                    <CircleCheck />
                    {/* <FaRegCircleCheck className="text-[#0BD6CA] w-6 h-6" />  */}
                    <span className="text-[#8B8D98] font-medium text-base">Ready-to-use AI product for all industries</span>
                </div>
                <div className="flex items-center justify-center gap-2">
                    <CircleCheck />
                    {/* <FaRegCircleCheck className="text-[#0BD6CA] w-6 h-6" />  */}
                    <span className="text-[#8B8D98] font-medium text-base">Fast and easy installation</span>
                </div>
            </div>
            <Button design={`secondary`} style=' px-4 py-3 ' handleClick={() => navigate(`/AllModels`)}>Explore AI Models</Button>
        </div>
    </div>
}

export default HeroSection;





export const CircleCheck = () => {
    return <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.5083 10.0125C20.5158 8.6746 20.2444 7.34977 19.7113 6.12255C19.2143 4.93133 18.4898 3.84846 17.5783 2.93455C16.6647 2.0232 15.5822 1.29869 14.3913 0.80155C13.1638 0.268397 11.8386 -0.00304237 10.5003 0.00454949C9.16239 -0.00290329 7.83756 0.268533 6.61034 0.80155C5.41915 1.2986 4.33628 2.02311 3.42234 2.93455C2.51101 3.84819 1.78651 4.93071 1.28934 6.12155C0.75619 7.34908 0.484751 8.67426 0.492342 10.0125C0.48489 11.3505 0.756326 12.6753 1.28934 13.9025C1.78635 15.0938 2.51087 16.1766 3.42234 17.0905C4.33595 18.0019 5.41848 18.7264 6.60934 19.2236C7.83687 19.7567 9.16205 20.0281 10.5003 20.0205C11.8383 20.028 13.1631 19.7566 14.3903 19.2236C15.5816 18.7266 16.6645 18.002 17.5783 17.0905C18.4897 16.177 19.2142 15.0944 19.7113 13.9035C20.2445 12.676 20.5159 11.3508 20.5083 10.0125ZM19.0083 10.0125C19.0155 11.1497 18.7838 12.2756 18.3283 13.3175C17.9009 14.3335 17.2801 15.2566 16.5003 16.0356C15.7275 16.8062 14.8122 17.4193 13.8053 17.8405C12.7634 18.296 11.6375 18.5277 10.5003 18.5205C9.36323 18.5277 8.23728 18.296 7.19534 17.8405C6.1836 17.4157 5.26137 16.8033 4.47734 16.0356C3.70965 15.2515 3.09722 14.3293 2.67234 13.3175C2.21687 12.2756 1.98521 11.1497 1.99234 10.0125C1.99234 8.84055 2.21934 7.73955 2.67234 6.70755C3.09366 5.70072 3.7067 4.78541 4.47734 4.01255C5.25631 3.23282 6.17943 2.61197 7.19534 2.18455C8.23728 1.72908 9.36323 1.49741 10.5003 1.50455C11.6723 1.50455 12.7733 1.73155 13.8053 2.18455C14.8164 2.60842 15.7326 3.2299 16.5003 4.01255C17.283 4.78026 17.9045 5.69651 18.3283 6.70755C18.7838 7.74948 19.0155 8.87543 19.0083 10.0125Z" fill="url(#paint0_linear_438_586)" />
        <path d="M15.2583 8.02055C15.2583 7.80155 15.1803 7.62155 15.0233 7.48155H15.0253C14.9544 7.41154 14.8699 7.3568 14.777 7.32068C14.6841 7.28456 14.5849 7.26784 14.4853 7.27155C14.2823 7.27155 14.1103 7.34255 13.9703 7.48255L9.70329 11.7465C9.65629 11.7935 9.58629 11.8165 9.49229 11.8165C9.41429 11.8165 9.34429 11.7935 9.28229 11.7465L7.03229 9.47255C6.96137 9.40254 6.87688 9.3478 6.78401 9.31168C6.69114 9.27556 6.59186 9.25884 6.49229 9.26255C6.28929 9.26255 6.11729 9.33255 5.97729 9.47255C5.82029 9.62955 5.74229 9.80955 5.74229 10.0125C5.74247 10.1138 5.76342 10.214 5.80384 10.3069C5.84425 10.3998 5.90329 10.4834 5.97729 10.5525L9.14129 13.7155C9.18729 13.7617 9.24225 13.7979 9.30279 13.822C9.36333 13.8461 9.42816 13.8575 9.49329 13.8555C9.55808 13.8572 9.62253 13.8457 9.6827 13.8216C9.74288 13.7975 9.79752 13.7614 9.84329 13.7155L15.0233 8.53555C15.0978 8.47219 15.1575 8.39322 15.1981 8.30422C15.2387 8.21522 15.2593 8.11837 15.2583 8.02055Z" fill="url(#paint1_linear_438_586)" />
        <defs>
            <linearGradient id="paint0_linear_438_586" x1="0.119316" y1="-0.404867" x2="11.4468" y2="-4.80767" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2BB2FF" />
                <stop offset="1" stopColor="#0BD6CA" />
            </linearGradient>
            <linearGradient id="paint1_linear_438_586" x1="0.119316" y1="-0.404867" x2="11.4468" y2="-4.80767" gradientUnits="userSpaceOnUse">
                <stop stopColor="#2BB2FF" />
                <stop offset="1" stopColor="#0BD6CA" />
            </linearGradient>
        </defs>
    </svg>

}