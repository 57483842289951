import axios from "axios";
import React from "react";
export const uploadFileToIPFS = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    const pinataMetadata = JSON.stringify({
      name: "File name",
    });
    formData.append("pinataMetadata", pinataMetadata);

    const pinataOptions = JSON.stringify({
      cidVersion: 0,
    });
    formData.append("pinataOptions", pinataOptions);
    const jwt =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI2NWQzNzA2Ny05OTUyLTRhOWItYTllYy1mMTAwYTQyN2I1NDQiLCJlbWFpbCI6InNoYWhhcnlhcnVtcmFuaUBnbWFpbC5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwicGluX3BvbGljeSI6eyJyZWdpb25zIjpbeyJpZCI6IkZSQTEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX0seyJpZCI6Ik5ZQzEiLCJkZXNpcmVkUmVwbGljYXRpb25Db3VudCI6MX1dLCJ2ZXJzaW9uIjoxfSwibWZhX2VuYWJsZWQiOmZhbHNlLCJzdGF0dXMiOiJBQ1RJVkUifSwiYXV0aGVudGljYXRpb25UeXBlIjoic2NvcGVkS2V5Iiwic2NvcGVkS2V5S2V5IjoiZTQ0NWQwM2U5OWUzYWFiMzA5MTAiLCJzY29wZWRLZXlTZWNyZXQiOiI4YWViMTU0N2ZmMmQzMDYzZjE2YWYxMDAwY2UxNGEyNGRkM2Y5YjczZGU3NGI0NDFmNmZlYTcxMDU3NjZjNWIwIiwiaWF0IjoxNzA2Njg4NDI4fQ.QMV6wQzp530fHrHEXhd_9s6m-mAbFv-OaqfRva6A9yE";
    try {
      const response = await axios.post(
        "https://api.pinata.cloud/pinning/pinFileToIPFS",
        formData,
        {
          maxBodyLength: 100,
          headers: {
            "Content-Type": `multipart/form-data;`,
            Authorization: `Bearer ${jwt}`, // Replace with your actual token
          },
        }
      );
      if (response && response.data.IpfsHash) {
        return response.data.IpfsHash;
      }
    } catch (error) {
      console.error(error);
    }
  };



  function findValueInObject(obj:any, key:string) {
    for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            if (prop === key) {

                return obj[key];
                // if(Array.isArray(obj[prop])){
                //   return obj[prop][0];
                // }else{
                //   return obj[prop];
                // }
            } else if (Array.isArray(obj[prop])) {
                const result = findValueInArrayOfObjects(obj[prop], key);
                if (result !== null) {
                    return result;
                }
            } else if (typeof obj[prop] === 'object') {
                const result:any = findValueInObject(obj[prop], key);
                if (result !== null) {
                    return result;
                }
            }
        }
    }
    return null; // Key not found
}


// Function to find a key within an array of objects with recursion
function findValueInArrayOfObjects(arr:any[], key:string) {
  for (let i = 0; i < arr.length; i++) {
      if (typeof arr[i] === 'object') {
          const result:any = findValueInObject(arr[i], key);
          if (result !== null) {
              return result;
          }
      }
  }
  return null; // Key not found
}




 export function displayValueForKey(apiResponse:any, key:string) {

    let value:any ;
    if(typeof apiResponse === `string`){
      return apiResponse;
    }else if(typeof apiResponse === 'object' && Array.isArray(apiResponse)){
    value = findValueInArrayOfObjects(apiResponse, key);
  }else if(typeof apiResponse === 'object' && !Array.isArray(apiResponse)){
    value = findValueInObject(apiResponse, key);
  }
    if (value !== null) {
      return value;
    } else {
      let firstKey = Object.keys(apiResponse)[0];
      if(firstKey && typeof apiResponse[firstKey] === "string"){
        return apiResponse[firstKey]
      }else{

        return null;
      }
    }
};




function copyToClipboard(code:any) {
  navigator.clipboard.writeText(code);
}

export function replaceBackticksWithCodeTag(text:string) {

  text = text.replace(/<br\s*\/?>|<br\s*\/?>/g, '\n');

  const regex = /```([\s\S]*?)```/g ;


  const parts = text.split(regex).map((part, index,array) => {
    if (index % 2 === 0) {

        return <React.Fragment key={index}>{part.split(/```(?=[^`]*$)/g).map((p,i)=>{
          if(i===0){
            return p
          }else{
            return <div className="p-2 border border-gray-300 rounded bg-gray-50" key={i}>
            <div className="flex items-center justify-between">
                <p className="text-sm font-bold text-gray-600">Code</p>
                <button className="text-xs font-semibold text-gray-500 uppercase" onClick={() => copyToClipboard(p)} >Copy Code</button>
            </div>
            <pre className="font-mono text-sm mt-2 p-2 bg-gray-900 text-white rounded-md overflow-auto">
                <code>{p}</code>
                {/* {`\n\n`}...Some text Missing... */}
            </pre>
        </div>
          }
        })}</React.Fragment>;
      
      } else {
          return (
              <div className="p-2 border border-gray-300 rounded bg-gray-50" key={index}>
                  <div className="flex items-center justify-between">
                      <p className="text-sm font-bold text-gray-600">Code</p>
                      <button className="text-xs font-semibold text-gray-500 uppercase" onClick={() => copyToClipboard(part)} >Copy Code</button>
                  </div>
                  <pre className="font-mono text-sm mt-2 p-2 bg-gray-900 text-white rounded-md overflow-auto">
                      <code>{part}</code>
                  </pre>
              </div>
          );
      }
  });

  return parts;

}




export const messageResponse = (inputType:string,response:any) => {

  if(response && inputType){

    if(Array.isArray(response)){
      return <div className="whitespace-pre-wrap text-justify flex flex-wrap max-w-[600px]">
          {response.map(img => <img src={img} className=" w-1/1 max-w-[250px] p-2 rounded-xl" alt={img} />)}
      </div>
    }
    
    
    if([`audio`].includes(inputType) && response?.startsWith(`http`)){
          switch (inputType) {
            case 'audio':
               return <audio controls>
              <source src={response} type="audio/mp3" />
              <source src={response} type="audio/ogg" />
              Your browser does not support the audio element.
            </audio>
          }
        }else if(response.startsWith(`data:image`) || response.startsWith(`http`)){
          return <img src={response} className=" w-1/1 max-w-[250px] p-2 rounded-xl" alt="" />
        }else{
          return <div className=" whitespace-pre-wrap text-justify" >
          {replaceBackticksWithCodeTag(response)}</div>
        }

  }


  }