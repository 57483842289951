import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import Image from "../Image";

interface CardProps {
  model: any;
}

const Card: React.FC<CardProps> = ({ model }) => {
  const { title, logo, organization, description, category } = model;

  return (
    <motion.div
      whileHover={{ scale: 1.05, rotate: 2 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.3 }}
      className="relative bg-[#e8f0fe]  min-h-[200px] cardPatternImage dark:bg-[#1f2937] rounded-xl p-4 flex-1 min-w-[275px] overflow-hidden shadow-lg dark:shadow-xl transition-transform transform hover:scale-105"
    >
      <Link to={`/chatbot?model=${model?.modelName}`} className="flex flex-col h-full  min-h-[165px]">
        <div className="flex items-center gap-4 mb-4">
          <motion.div
            className="w-[74px] h-[74px] bg-gray-200 dark:bg-[#3b3b3b] rounded-xl flex items-center justify-center overflow-hidden shadow-md dark:shadow-lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <Image
              src={logo ?? `/image/notFound.PNG`}
              style="w-full h-full object-cover"
            />
          </motion.div>
          <div className="flex flex-col">
            <h6 className="font-semibold text-md text-gray-900 dark:text-gray-100">{title}</h6>
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">by {organization}</p>
          </div>
        </div>
        <p className="text-sm text-gray-600 dark:text-gray-300 flex-1 line-clamp-2">
          {description}
        </p>
        <div className="mt-4">
          <span className="py-1.5 px-3 text-xs font-medium rounded bg-gray-200 dark:bg-gray-600 text-gray-600 dark:text-gray-300">
            {category}
          </span>
        </div>
        <motion.div
          className="absolute inset-0 bg-gradient-to-br from-[#003973] to-[#47a1ff] opacity-0 group-hover:opacity-60 transition-opacity duration-300"
        />
      </Link>
    </motion.div>
  );
};

export default Card;
